import * as React from 'react';
import {NavigateParams} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import { View,TouchableOpacity, Image, Text, Alert, Linking, TextInput, ScrollView} from 'react-native';
import ButtonComponent from '../components/ButtonComponent';
import NavBarSeller from '../components/NavBarSeller';
import Card from '../components/Card';
import cart_icon from '../assets/images/icons/cart-shopping-solid.png';
import pix_icon from '../assets/images/icons/pix.png';
import trash_icon from '../assets/images/icons/trash-can-solid.png';
import { getData, removeData } from '../services/StorageService';
import {getCreditos, getDesconto, setNewOrderBySlice, setNewOrderSerie, setPaymentPix, updateOrderQRCode, getClientsBySeller, getNewCard, getNewCardOrder, getStatusStore,  getTutor,  getVendaBloqueada,  setNewOrderBySeller, setReturnCard, setReturnCardOrder, getUsuario } from '../services/ApiServices';

export default class StoreSeller extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/', params: null},
            styles : styles,
            user: {},
            currentUrl: "",
            currentSystem: "",
            systemColor: 'white',
            currentCard: {numeros: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0], codigo: "00000-0", data: "0000-00-00", valor: "00,00", horario: "00:00"},
            cartList: [],
            clients: [],
            openModalClients: false,
            clientSelectedName: "",
            clientSelected: null,
            isLoading: false,
            vendaBloquada: false,
            tutor: {bloqVendas: false},
            orderSize: 0,
            creditos: 0,
            desconto: 0,
            bloqVendas: false

        }

    }

    componentDidMount(){
        getData("current_system_color").then(res => this.setState({systemColor : res}));
        this.initializeDataState().then(res => {
            this.state.currentUrl = res.url;
            this.state.user = res.user;
            getClientsBySeller(res.url, res.user._id).then(res => this.setState({clients : res.sort(this.sortByName)}))

            if(res.user.vendedorApp){
                getCreditos(res.url, res.user._id).then(res => {
                    this.setState({creditos: res});
                    if(res > 0)
                        getNewCardOrder(this.state.currentUrl, this.state.user._id, this.state.cartList).then(res => res? this.setState({currentCard : res.data? res : this.state.currentCard}) : console.log(''))
                })
            }
            else{
                getNewCard(res.url).then(res => res? this.setState({currentCard : res.data? res : this.state.currentCard}) : console.log(''))
            }
            getStatusStore(res.url).then(res => this.setState({statusStore : res.enableStore, sorteioStatus: res.sorteioStatus}))
            getTutor(res.url, res.user.tutorId).then(res => this.setState({tutor :res}))
            getVendaBloqueada(res.url).then(res => this.setState({vendaBloquada :  res}))

        })

        getData("current_url").then(url => {
            getData("current_system").then(sys => {
                getData(sys+"_user").then(user => {
                    getUsuario(url, JSON.parse(user)._id).then(res => {
                        if(JSON.parse(user).status != res.status){
                            this.logout(sys)
                        }
                    })
                });
            });
        });

    }

    logout(sys){
        removeData(sys + "_system_seller_auth");
        removeData(sys + "_user");
        this.setState({redirect : {status: true, value: ''}});
        window.location.reload()
    }

    async initializeDataState(){
        let url = await getData("current_url");
        let sys = await getData("current_system");
        this.setState({currentUrl: url, currentSystem: sys})
        
        let user = await getData(sys+"_user");
        this.setState({user : JSON.parse(user)});
        if(JSON.parse(user).vendedorApp)
            getDesconto(url).then(res => this.setState({desconto: res}));
        return {url, user: JSON.parse(user)};
    }
    
    getFullSystemName(){
        return this.state.currentSystem == "cf"?  "CartelaFlash" : this.state.currentSystem == "fd"? "FlashDay" : "Sorteflash"
    }

    nextCard(){
        this.setState({isLoading : true})
        if(this.state.currentCard.codigo !== "00000-0" && !JSON.stringify(this.state.cartList).includes(JSON.stringify(this.state.currentCard)) )
            setReturnCard(this.state.currentUrl, this.state.currentCard);
        getNewCard(this.state.currentUrl).then(res => {
            res? this.setState({currentCard : res.data? res : this.state.currentCard}) : console.log('');
            this.setState({isLoading : false})
        });
    }
    returnCard(card, index){
        this.state.cartList.splice(index,1);
        this.setState({cartList: this.state.cartList});
        setReturnCard(this.state.currentUrl, card).then(res => console.log(res));
    }

    returnCardOrder(card, index, cambistaId){
        this.state.cartList.splice(index,1);
        this.setState({cartList: this.state.cartList});
        setReturnCardOrder(this.state.currentUrl, card, cambistaId).then(res => console.log(res));
    }


    async nextCardOrder(){
        this.setState({isLoading : true})
        if(this.state.currentCard.codigo !== "00000-0" && !JSON.stringify(this.state.cartList).includes(JSON.stringify(this.state.currentCard)) )
            setReturnCardOrder(this.state.currentUrl, this.state.currentCard, this.state.user._id);
        const res = await getNewCardOrder(this.state.currentUrl, this.state.user._id, this.state.cartList);
        res ? this.setState({ currentCard: res.data ? res : this.state.currentCard }) : console.log('');
        this.setState({ isLoading: false });
        return res;
    }

    addCardToCart(){
        if(this.state.currentCard.codigo !== "00000-0" && !this.state.cartList.some(card => this.state.currentCard.codigo == card.codigo)){
            this.state.cartList.push(this.state.currentCard);
            this.setState({cartList: this.state.cartList});
        }
        else if(!this.state.isLoading && this.state.user.vendedorApp && this.state.currentCard.codigo !== "00000-0" && this.state.cartList.some(card => this.state.currentCard.codigo == card.codigo)){
            this.nextCardOrder().then(res => {
                this.addCardToCart();
                console.log(res)
            })
        }
        if(this.state.user.vendedorApp){
            this.nextCardOrder();
        }
        else{
            this.nextCard();
        }

        if(this.state.cartList.length == this.state.creditos){
            this.setState({isLoading : false})
        }

    }

    submitOrder(){
        this.setState({bloqVendas : true})
        this.setState({isLoading : true})
        setNewOrderBySeller(this.state.currentUrl, {
            cartelas: this.state.cartList,
            clienteId: this.state.clientSelected,
            cambistaId: this.state.user,
            gerenteId: this.state.user.tutorId,
            data: new Date()
        }).then(res => {
            this.setState({cartList: []});
            this.setState({isLoading : false})
            if(res){
                //this.setState({redirect : {status: true, value: 'PaymentSeller', params: {order: res}}})
                this.setState({redirect : {status: true, value: 'OrdersSeller'}})
            }
        })

    }

    submitOrderBySlice(){
        this.setState({bloqVendas : true})
        this.setState({isLoading : true})
        const cartelas =  this.state.cartList;
        setNewOrderBySlice(this.state.currentUrl, {
            cartelas: cartelas,
            isCredit: true,
            clienteId: this.state.clientSelected,
            cambistaId: this.state.user,
            gerenteId: this.state.user.tutorId,
            data: new Date()
        }).then(res => {
            this.setState({cartList: []});
            this.setState({isLoading : false})
            if(res){
                //this.setState({redirect : {status: true, value: 'PaymentSeller', params: {order: res}}})
                this.setState({redirect : {status: true, value: 'OrdersSeller'}});
            }
        })

    }

    openURL = async (url) => {
        const supported = await Linking.canOpenURL(url);
        if (supported) {
          await Linking.openURL(url);
        } else {
          Alert.alert(`Don't know how to open this URL: ${url}`);
        }
    }

    sortByName(a,b){
        if (a.nome.toLowerCase() < b.nome.toLowerCase() ) {
            return -1;
          }
          if (a.nome.toLowerCase()  > b.nome.toLowerCase() ) {
            return 1;
          }
          return 0;
    }

    handlerModalClinet(){
        this.setState({openModalClients : !this.state.openModalClients})
    }

    submitPixOrderSerie(){
        this.setState({bloqVendas : true})
        this.setState({isLoading : true});
        setNewOrderSerie(this.state.currentUrl, {
            orderSize: this.state.orderSize,
            cambistaId: this.state.user,
            isCredit: true
        }).then(res => {
            this.setState({cartList: []});
            this.setState({isLoading : false});
            let order = res;
            order.cambistaId = order.cambistaId._id;
            setPaymentPix(this.state.currentUrl, order, this.state.user.email).then(res => {
                updateOrderQRCode(this.state.currentUrl, order._id, res.body.point_of_interaction.transaction_data.qr_code )
                this.setState({redirect : {status: true, value: 'PixPaymentSeller', params: {order: {...order, desconto: this.state.user.comissao > 0 ? this.state.user.comissao: this.state.desconto, cambistaId: this.state.user, codigoCopiaCola:   res.body.point_of_interaction.transaction_data.qr_code}}}})
            })
        })
    }

    render() {
        if(!this.state.user.status || this.state.statusStore || this.state.sorteioStatus || this.state.tutor.bloqVendas || this.state.vendaBloquada){
            return (
                <NavBarSeller isLoading={this.state.isLoading} >
            {this.state.redirect.status? <NavigateParams navigation={this.props.navigation} screen={this.state.redirect.value} params={this.state.redirect.params}></NavigateParams> : <Text></Text>}
                    <View  style={{...this.state.styles.mt32, height: 'auto', width: '100%', ...this.state.styles.row, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                        <View style={{...this.state.styles.bgDark, padding: 12, width: '80%', ...this.state.styles.row,...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                            <Text style={{...this.state.styles.cLight, ...this.state.styles.bold}}>
                                Indisponivel no momento!
                            </Text>
                        </View>
                    </View>
                </NavBarSeller>
            )
        }
        else {

        if(this.state.user.vendedorApp){
            return (
                <NavBarSeller navigation={this.props.navigation} isLoading={this.state.isLoading} >
            {this.state.redirect.status? <NavigateParams navigation={this.props.navigation} screen={this.state.redirect.value} params={this.state.redirect.params}></NavigateParams> : <Text></Text>}
                        <ScrollView>
                    <View  style={{...this.state.styles.mt16, height: 'auto', width: '100%', ...this.state.styles.row, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                        <View style={{...this.state.styles.bgLight, padding: 12, width: '80%', ...this.state.styles.row,...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                            <Text style={{...this.state.styles.cDanger, ...this.state.styles.bold}}>
                                Comissão R$ {this.state.user.comissao > 0? this.state.user.comissao : parseFloat(this.state.desconto).toFixed(2)}
                            </Text>
                        </View>
                    </View>
                    <View  style={{...this.state.styles.mt16, height: 'auto', width: '100%', ...this.state.styles.row, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                        <View style={{...this.state.styles.bgDark, padding: 12, width: '80%', ...this.state.styles.row,...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                            <Text style={{...this.state.styles.cLight, ...this.state.styles.bold}}>
                                Cartelas
                            </Text>
                        </View>
                    </View>
                    <View  style={{...this.state.styles.mt8, height: 64, width: '100%', ...this.state.styles.row, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                        <View style={{...this.state.styles.bgDark, height: 64,padding: 12, width: '60%', ...this.state.styles.row,...this.state.styles.alignCenter, ...this.state.styles.justifyCenter, ...this.state.styles.rounded}}>
                            <TouchableOpacity onPressIn={() => this.setState({orderSize: 5})}><View style={{ height: 56, width: 56, backgroundColor: this.state.orderSize == 5? 'green' : 'white',...this.state.styles.rounded, ...this.state.styles.row,...this.state.styles.alignCenter, ...this.state.styles.justifyCenter,}}>
                                <Text style={{color: this.state.orderSize == 5? 'white' : 'gray', ...this.state.styles.bold,...this.state.styles.fs18 }}>5</Text>
                            </View></TouchableOpacity>
                            {/* <TouchableOpacity onPressIn={() => this.setState({orderSize: 10})}><View style={{ height: 56, width: 56, backgroundColor: this.state.orderSize == 10? 'green' : 'white',...this.state.styles.rounded, ...this.state.styles.row,...this.state.styles.alignCenter, ...this.state.styles.justifyCenter,}}>
                                <Text style={{color: this.state.orderSize == 10? 'white' : 'gray', ...this.state.styles.bold,...this.state.styles.fs18 }}>10</Text>
                            </View></TouchableOpacity> */}
                            <TouchableOpacity onPressIn={() => this.setState({orderSize: 30})}><View style={{...this.state.styles.ml8,  height: 56, width: 56,backgroundColor: this.state.orderSize == 30? 'green' : 'white',...this.state.styles.rounded, ...this.state.styles.row,...this.state.styles.alignCenter, ...this.state.styles.justifyCenter,}}>
                                <Text style={{color: this.state.orderSize == 30? 'white' : 'gray',...this.state.styles.bold,...this.state.styles.fs18 }}>30</Text>
                            </View></TouchableOpacity>
                            <TouchableOpacity onPressIn={() => this.setState({orderSize: 50})}><View style={{...this.state.styles.ml8,  height: 56, width: 56,backgroundColor: this.state.orderSize == 50? 'green' : 'white',...this.state.styles.rounded, ...this.state.styles.row,...this.state.styles.alignCenter, ...this.state.styles.justifyCenter,}}>
                                <Text style={{color: this.state.orderSize == 50? 'white' : 'gray',...this.state.styles.bold,...this.state.styles.fs18 }}>50</Text>
                            </View></TouchableOpacity>
                            {/* <TouchableOpacity onPressIn={() => this.setState({orderSize: 100})}><View style={{...this.state.styles.ml8, height: 56, width: 56,backgroundColor: this.state.orderSize == 100? 'green' : 'white',...this.state.styles.rounded, ...this.state.styles.row,...this.state.styles.alignCenter, ...this.state.styles.justifyCenter,}}>
                                <Text style={{color: this.state.orderSize == 100? 'white' : 'gray', ...this.state.styles.bold,...this.state.styles.fs18 }}>100</Text>
                            </View></TouchableOpacity> */}
                        </View>
                    </View>
                    <View  style={{...this.state.styles.mt16, height: 'auto', width: '100%', ...this.state.styles.row, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                        <View style={{width: '100%', paddingLeft: 32, paddingRight: 32, ...this.state.styles.mt8}}><ButtonComponent disabled={this.state.orderSize == 0 || this.state.bloqVendas} action={this.submitPixOrderSerie.bind(this)} variant="success_filled" icon={pix_icon}>Comprar crédito de venda</ButtonComponent></View>
                    </View>

                <View  style={{...this.state.styles.mt16, height: 'auto', width: '100%', ...this.state.styles.row, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                    <View style={{...this.state.styles.bgLight, padding: 12, width: '80%', ...this.state.styles.row,...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                        <Text style={{...this.state.styles.cDanger, ...this.state.styles.bold}}>
                            Você possui {this.state.currentCard.codigo != "00000-0"? this.state.creditos - this.state.cartList.length :  0} cartelas para a venda!
                        </Text>
                    </View>
                </View>

                <View  style={{...this.state.styles.mt16, height: 'auto', width: '100%', ...this.state.styles.row, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                    <View style={{...this.state.styles.bgLight, padding: 12, width: '80%', ...this.state.styles.row,...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                        <Text style={{...this.state.styles.cDark, ...this.state.styles.bold}}>
                            Fique tranquilo!! Os créditos que você não ultilizar serão convertidos em cartelas para você competir no sorteio atual!
                        </Text>
                    </View>
                </View>
                {this.state.creditos > 0? 
                
                <View>
                <View style={{width: '100%', paddingLeft: 32, paddingRight: 32,...this.state.styles.col, ...this.state.styles.mt16, ...this.state.styles.justifyCenter}}>
                <View style={{padding: 12, width: '100%'}}>
                    <ButtonComponent disabled={false} action={() => this.setState({redirect : {status: true, value: 'ClientSingUpSellerScreen'}})} variant="success_filled"> Cadastrar novo cliente </ButtonComponent>
                </View>
                <TextInput style={{...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} 
                                        placeholder='Nome do cliente'
                                        onChangeText={(value) => {this.setState({clientSelectedName : value, openModalClients: true})}}
                                        keyboardType="default"
                                        value={this.state.clientSelectedName}
                ></TextInput>
                {this.state.clientSelectedName !== "" && this.state.openModalClients? 
                    <View style={{width: '90%', height: 'auto', ...this.state.styles.col, ...this.state.styles.myShadow}}>
                        {this.state.clients.map(client => {
                        if(client.nome.toLowerCase().includes(this.state.clientSelectedName.toLowerCase()) )
                            return <TouchableOpacity onPress={() => {this.setState({clientSelected : client, clientSelectedName : client.nome, openModalClients: false});}}><Text style={{...this.state.styles.bold, marginLeft : 12}}>{client.nome}</Text></TouchableOpacity>
                        })}
                    </View>
                : <Text></Text>}
                </View>
                {(this.state.creditos - this.state.cartList.length ) > 0? 
                
                <View>
                <Card onArrowPress={this.nextCardOrder.bind(this)} currentCard={this.state.currentCard}></Card>
                <View style={{width: '100%', paddingLeft: 32, paddingRight: 32, ...this.state.styles.mt8}}><ButtonComponent disabled={(this.state.creditos - this.state.cartList.length ) == 0} action={this.addCardToCart.bind(this)} variant="primary_filled" icon={cart_icon}>Carrinho</ButtonComponent></View>
                </View>
                
                : <Text></Text>}
  
                {this.state.cartList.length > 0? <View style={{width: '100%', paddingLeft: 32, paddingRight: 32, ...this.state.styles.mt16, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}><Text style={{...this.state.styles.fs18, ...this.state.styles.bold, ...this.state.styles.cDark}}>{`Cartelas: ${this.state.cartList.length} - Valor : R$${this.state.cartList.length * parseFloat(this.state.cartList[0].valor).toFixed(2)}`}</Text></View> : <Text></Text>}
                <View style={{width: '100%', paddingLeft: 32, paddingRight: 32, ...this.state.styles.mt16, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                    {this.state.cartList.map((card,index) => 
                    <View style={{...this.state.styles.mt8,
                        ...this.state.styles.myShadow, 
                        height: 40, width: '100%', 
                        ...this.state.styles.row, 
                        justifyContent: 'space-around', 
                        ...this.state.styles.alignCenter}}>
                        <Text style={{...this.state.styles.fs10, ...this.state.styles.bold, ...this.state.styles.cDark}}>{`R$${card.valor} - ${card.data.split('-')[2]+'-'+card.data.split('-')[1]+'-'+card.data.split('-')[0]} - Cartela: ${card.codigo}`}</Text>
                            <TouchableOpacity onPress={() => {this.returnCardOrder(card,index, this.state.user._id)}}><Image source={trash_icon} style={{width : 22, height: 22}}></Image></TouchableOpacity>
                        </View>
                    )}
                </View>
                {this.state.cartList.length > 0 && this.state.clientSelected? <View style={{width: '100%', paddingLeft: 32, paddingRight: 32, ...this.state.styles.mt64}}><ButtonComponent disabled={this.state.bloqVendas} action={this.submitOrderBySlice.bind(this)} variant="primary_filled">Validar</ButtonComponent></View> : <Text></Text>}
                </View>
                
                : <Text></Text>}

                </ScrollView>
                </NavBarSeller>
            );
        }
        else{  
        return (
            <NavBarSeller navigation={this.props.navigation} isLoading={this.state.isLoading} >
            {this.state.redirect.status? <NavigateParams navigation={this.props.navigation} screen={this.state.redirect.value} params={this.state.redirect.params}></NavigateParams> : <Text></Text>}
                <View style={{height:40, ...this.state.styles.row, backgroundColor : this.state.systemColor, ...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                    <Text  style={{...this.state.styles.bold, ...this.state.styles.fs14, ...this.state.styles.cLight}}>Escolher cartelas</Text>
                    {/* <Image source={cart_icon} style={{width : 22, height: 22, marginLeft: 12}} /> */}
                </View>
                <View style={{width: '100%', paddingLeft: 32, paddingRight: 32,...this.state.styles.col, ...this.state.styles.mt16, ...this.state.styles.justifyCenter}}>

                <TextInput style={{...this.state.styles.mt8, width: '100%',  height: 40, paddingLeft: 6, ...this.state.styles.myShadow}} 
                                        placeholder='Nome do cliente'
                                        onChangeText={(value) => {this.setState({clientSelectedName : value, openModalClients: true})}}
                                        keyboardType="default"
                                        value={this.state.clientSelectedName}
                ></TextInput>
                {this.state.clientSelectedName !== "" && this.state.openModalClients? 
                    <View style={{width: '90%', height: 'auto', ...this.state.styles.col, ...this.state.styles.myShadow}}>
                        {this.state.clients.map(client => {
                        if(client.nome.toLowerCase().includes(this.state.clientSelectedName.toLowerCase()) )
                            return <TouchableOpacity onPress={() => {this.setState({clientSelected : client, clientSelectedName : client.nome, openModalClients: false});}}><Text style={{...this.state.styles.bold, marginLeft : 12}}>{client.nome} - {client.telefone}</Text></TouchableOpacity>
                        })}
                    </View>
                : <Text></Text>}
                </View>
                <Card onArrowPress={this.nextCard.bind(this)} currentCard={this.state.currentCard}></Card>
                <View style={{width: '100%', paddingLeft: 32, paddingRight: 32, ...this.state.styles.mt8}}><ButtonComponent disabled={false} action={this.addCardToCart.bind(this)} variant="primary_filled" icon={cart_icon}>Carrinho</ButtonComponent></View>
                {this.state.cartList.length > 0? <View style={{width: '100%', paddingLeft: 32, paddingRight: 32, ...this.state.styles.mt16, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}><Text style={{...this.state.styles.fs18, ...this.state.styles.bold, ...this.state.styles.cDark}}>{`Cartelas: ${this.state.cartList.length} - Valor : R$${this.state.cartList.length * parseFloat(this.state.cartList[0].valor).toFixed(2)}`}</Text></View> : <Text></Text>}
                <View style={{width: '100%', paddingLeft: 32, paddingRight: 32, ...this.state.styles.mt16, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                    {this.state.cartList.map((card,index) => 
                    <View style={{...this.state.styles.mt8,
                        ...this.state.styles.myShadow, 
                        height: 40, width: '100%', 
                        ...this.state.styles.row, 
                        justifyContent: 'space-around', 
                        ...this.state.styles.alignCenter}}>
                        <Text style={{...this.state.styles.fs10, ...this.state.styles.bold, ...this.state.styles.cDark}}>{`R$${card.valor} - ${card.data.split('-')[2]+'-'+card.data.split('-')[1]+'-'+card.data.split('-')[0]} - Cartela: ${card.codigo}`}</Text>
                            <TouchableOpacity onPress={() => {this.returnCard(card,index)}}><Image source={trash_icon} style={{width : 22, height: 22}}></Image></TouchableOpacity>
                        </View>
                    )}
                </View>
                {this.state.cartList.length > 0 && this.state.clientSelected? <View style={{width: '100%', paddingLeft: 32, paddingRight: 32, ...this.state.styles.mt64}}><ButtonComponent disabled={this.state.bloqVendas} action={this.submitOrder.bind(this)} variant="primary_filled">Validar</ButtonComponent></View> : <Text></Text>}
            </NavBarSeller>
            )
        }
        }
    }
}

import axios from 'axios';



export const getUsuario = async (url,_id) => {

    try {
        const response = await axios.get(url + "/usuario/"+_id);
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export const getUsuarioByTelefone = async (url,telefone) => {

    try {
        const response = await axios.get(url + "/usuario/tel/"+telefone);
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export const getSerie = async (url, _id) => {

    try {
        const response = await axios.get(url + "/serie/"+_id);
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export const checkSerieAvaliable = async (url,_id) => {

    try {
        const response = await axios.get(url + "/serie/check/"+_id);
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export const checkCartelaDaSerie = async (url,codigo) => {

    try {
        const response = await axios.get(url + "/serie/check/cartela/"+codigo);
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export const checkCartelaDoPedido = async (url,codigo) => {

    try {
        const response = await axios.get(url + "/pedido/check/cartela/"+codigo);
        return response.data;
    } catch (error) {
        console.log(error)
    }
}


export const getCartelaDaSerie = async (url,serieId, cartelaCodigo) => {

    try {
        const response = await axios.post(url + "/serie/cartela/codigo", {serieId, cartelaCodigo});
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export const updateCartelaDoPedido= async (url,user, cartelaCodigo) => {

    try {
        const response = await axios.post(url + "/pedido/update/cartela/codigo", {cartelaCodigo, user});
        return response.data;
    } catch (error) {
        console.log(error)
    }
}



export const getBanner = async (url) => {

    try {
        const response = await axios.get(url + "/banner");
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export const getLive = async (url) => {

    try {
        const response = await axios.get(url + "/url-live-stream");
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const getCurrentNumbers = async (url) => {

    try {
        const response = await axios.get(url + "/current-numbers");
        return response.data;
    } catch (error) {
        console.log(error)
    }

}


export const getVendaBloqueada = async (url) => {

    try {
        const response = await axios.get(url + "/venda-bloqueada");
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const singupPasswordClient = async (url, client) => {

    try {
        const response = await axios.post(url+'/client/singup/password', {...client});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}


export const singupPasswordClientTelefone = async (url, client) => {

    try {
        const response = await axios.post(url+'/client/singup/telefone/password', {...client});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}


export const getSAC = async () => {

    try {
        const response = await axios.get('https://sorteflash.com:5000/api/parametros/get-sac');
        return response.data.contatoSAC;
    } catch (error) {
        console.log(error)
    }

}

export const singupPasswordSeller = async (url, seller) => {

    try {
        const response = await axios.post(url+'/seller/singup/password', {...seller});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const singupClient = async (url, client) => {

    try {
        const response = await axios.post(url+'/client/singup', {...client});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const singupSellerClient = async (url, client) => {

    try {
        const response = await axios.post(url+'/seller/client/singup', {...client});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const singinClient = async (url, form) => {

    try {
        const response = await axios.post(url+'/client/singin', {...form});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const resetClientPass = async (url, email) => {

    try {
        const response = await axios.post(url+'/client/reset-password', {email : email});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}


export const singinSeller = async (url, form) => {

    try {
        const response = await axios.post(url+'/seller/singin', {...form});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}


export const getNewCard = async (url) => {

    try {
        const response = await axios.get(url+'/new-card');
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const getNewCardOrder = async (url,cambistaId, cartList) => {

    try {
        const response = await axios.post(url+'/new-card-order-slice', {cambistaId, cartList});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}


export const getCreditos = async (url,cambistaId) => {

    try {
        const response = await axios.post(url+'/seller-credits',{cambistaId});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const setReturnCard = async (url, card) => {

    try {
        const response = await axios.post(url+'/return-card', {cartela : card});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const setReturnCardOrder = async (url, card,cambistaId) => {

    try {
        const response = await axios.post(url+'/return-card-order', {cartela : card, cambistaId});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const setNewOrder = async (url, form) => {

    try {
        const response = await axios.post(url+'/new-order', {...form});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const setNewOrderQRCode = async (url, form) => {

    try {
        const response = await axios.post(url+'/new-order-qrcode', {...form});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const setNewOrderBySlice = async (url, form) => {

    try {
        const response = await axios.post(url+'/new-order-serie-slice', {...form});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const setNewOrderSerie = async (url, form) => {

    try {
        const response = await axios.post(url+'/new-order-serie', {...form});
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export const setNewOrderBySeller = async (url, form) => {

    try {
        const response = await axios.post(url+'/new-order-seller', {...form});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}


export const getClientOrders = async (url, id) => {

    try {
        const response = await axios.post(url+'/client-orders', {clienteId : id});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}


export const getSellerOrders = async (url, id) => {

    try {
        const response = await axios.post(url+'/seller-orders', {cambistaId : id});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const getSellerOrdersHistoric = async (url, id, historico) => {

    try {
        const response = await axios.post(url+'/seller-orders-historic', {cambistaId : id, historico});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const getSellerOrdersHistoricJustPrepayed = async (url, id, historico) => {

    try {
        const response = await axios.post(url+'/seller-orders-historic-just-prepayed', {cambistaId : id, historico});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}



export const getClientPendingOrders = async (url, id) => {

    try {
        const response = await axios.post(url+'/client-pending-order', {clienteId : id});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const getHistorics = async (url) => {

    try {
        const response = await axios.get(url+'/historicos');
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const getHistoricsOrders = async (url, clienteId, dataInicial, dataFinal) => {

    try {
        const response = await axios.post(url+'/client-historic-orders',{clienteId, dataInicial, dataFinal});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const getHistoricsRewards = async (url, dataInicial, dataFinal) => {

    try {
        const response = await axios.post(url+'/historic-rewards',{dataInicial, dataFinal});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}


export const getHistoricsRewardsInterval = async (url, dataInicial, dataFinal) => {

    try {
        const response = await axios.post(url+'/historic-rewards-interval',{dataInicial, dataFinal});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}


export const getCurrentRewards = async (url, dataInicial, dataFinal) => {

    try {
        const response = await axios.get(url+'/current-rewards',{dataInicial, dataFinal});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const getClientsBySeller = async (url, sellerId) => {

    try {
        const response = await axios.post(url+'/seller/clients',{sellerId : sellerId});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const getSearchOrder = async (url, codigo) => {

    try {
        const response = await axios.post(url+'/seller/search-order-code',{codigo : codigo});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const setReceiveOrder = async (url, orderId, sellerId) => {

    try {
        const response = await axios.post(url+'/seller/receive-order',{pedidoId : orderId, cambistaId : sellerId});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const getSellerSeries = async (url, sellerId) => {

    try {
        const response = await axios.post(url+'/seller/series',{cambistaId : sellerId});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const updateSellerSerie = async (url, serie) => {

    try {
        const response = await axios.post(url+'/seller/update-serie',{serie});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const getSellerReports = async (url, cambistaId, dataInicial, dataFinal, type) => {

    try {
        const response = await axios.post(url+"/orders/reports",{cambistaId, dataInicial, dataFinal, type});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}


export const createSellerCreateComission = async (url, usuarioId, periodo, valor) => {

    try {
        const response = await axios.post(url+"/criar-comissao",{usuarioId, periodo, valor});
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export const getSellerComissoesUsusario = async (url, usuarioId) => {

    try {
        const response = await axios.post(url+"/obter-comissoes-usuario",{usuarioId});
        return response.data;
    } catch (error) {
        console.log(error)
    }
}



export const setPaymentPix = async (url,pedido, email) => {

    try {
        const response = await axios.post(url + '/payment-pix', {pedido : pedido, email: email});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const getValorCartela = async (url) => {

    try {
        const response = await axios.get(url + '/valor-cartela');
        return response.data.valorCartela;
    } catch (error) {
        console.log(error)
    }

}


export const updateOrderQRCode = async (url,id,codigoCopiaCola) => {

    try {
        const response = await axios.post(url + '/update-order-qrcode', {_id : id, codigoCopiaCola : codigoCopiaCola});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const getStatusStore = async (url) => {

    try {
        const response = await axios.get(url + '/enable-store');
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const getTutor = async (url, tutorId) => {

    try {
        const response = await axios.post(url + '/return-tutor',{tutorId});
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export const getDesconto = async (url, tutorId) => {

    try {
        const response = await axios.get(url + '/desconto');
        return response.data;
    } catch (error) {
        console.log(error)
    }

}


export const updateUsuario = async (url, usuario) => {

    try {
        const response = await axios.post(url + '/usuario/update/', usuario);
        return response.data;
    } catch (error) {
        console.log(error)
    }

}